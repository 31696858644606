import styled from "styled-components";

export const ButtonPrimary = (props) => {
  return (
    <Button
      onClick={props.onClick}
      style={props.style}
      theme={props.theme}
      fontsize={props.fontsize}
    >
      <div
        style={{
          marginLeft: props.icon && !props.leftAlign ? "auto" : "unset",
          marginRight: props.leftAlign ? "auto" : "unset",
        }}
      >
        {props.text}
      </div>

      {props.iconDivider && <IconDivider />}
      {props.icon && (
        <Icon
          src={props.icon}
          divider={props.iconDivider}
          style={props.iconStyle}
          theme={props.theme}
        />
      )}
    </Button>
  );
};

const IconDivider = styled.div`
  border-left: 1px solid #f5f5f5;
  height: 20px;
  margin-left: auto;
`;

const Icon = styled.img`
  height: 14px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding-left: 8px;
  transition: all 0.125s;

  filter: ${(props) =>
    props.theme === "blue"
      ? "invert(100%) sepia(0%) saturate(7500%) hue-rotate(290deg) brightness(106%) contrast(104%)"
      : "unset"};
`;

const Button = styled.button`
  font-family: "SkyTextMedium";
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 3px;
  width: max-content;
  height: 36px;
  padding: 5px 8px;
  transition: all 0.125s;
  // font-size: 16px;
  font-size: ${(props) => (props.fontsize === "sm" ? "14px" : "16px")};
  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};

  background: ${(props) => {
    switch (props.theme) {
      case "white":
        return "#fcfcfc";
      case "red":
        return "#f5f5f5";
      case "blue":
        return "#0072c9";
      default:
        return "unset";
    }
  }};
  color: ${(props) => {
    switch (props.theme) {
      case "white":
        return "#333333";
      case "red":
        return "#cccccc";
      case "blue":
        return "#ffffff";
      default:
        return "unset";
    }
  }};
  border-color: ${(props) => {
    switch (props.theme) {
      case "white":
        return "#0072c9";
      case "red":
        return "#f5f5f5";
      case "blue":
        return "#ffffff";
      default:
        return "unset";
    }
  }};

  :hover {
    border-color: ${(props) => {
      switch (props.theme) {
        case "white":
          return "#0072c9";
        case "red":
          return "#f5f5f5";
        case "blue":
          return "#3f94d6";
        default:
          return "unset";
      }
    }};
    color: ${(props) => {
      switch (props.theme) {
        case "white":
          return "#0072c9";
        case "red":
          return "#cccccc";
        case "blue":
          return "#ffffff";
        default:
          return "unset";
      }
    }};
    background: ${(props) => props.theme === "blue" && "#1484d0"};
  }

  :hover ${Icon} {
    filter: ${(props) =>
      props.theme !== "blue" && props.onClick &&
      "invert(48%) sepia(96%) saturate(340%) hue-rotate(163deg) brightness(92%) contrast(93%)"};
  }
`;

import { useRef, useState } from "react";
import styled from "styled-components";
import ico_mesh from "../../../assets/images/ico_mesh.svg";
import ico_arrow from "../../../assets/images/ico_down_arrow.svg";
import ico_folder from "../../../assets/images/ico_folder.svg";

export const SceneHierarchy = ({model, selected, setSelected, hierarchyWidth, setDragging}) => {
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const contentRef = useRef();

    const onSelectionChanged = (uuid, e, double = false) => {
        var newSelected = {...selected};
        var allNodes = document.getElementsByClassName("NodeContainer");
        var selectedNode = document.getElementById(uuid);
        var selectedIndex = [].indexOf.call(allNodes, selectedNode);

        if (double) {
            model.traverse(function (node) {
                if (node.uuid === uuid) {
                    newSelected[uuid] = true;
                    node.traverse(function(child) {
                        newSelected[child.uuid] = true;
                    });
                }
            });
        } else {
            if (e.shiftKey) {
                if (lastSelectedIndex || lastSelectedIndex === 0) {
                    if (lastSelectedIndex === selectedIndex) {
                        Object.keys(newSelected).forEach(v => newSelected[v] = false);
                        for (var i = lastSelectedIndex; i < allNodes.length; i++) {
                            newSelected[allNodes[i].id] = true;
                        }
                    } else if (lastSelectedIndex < selectedIndex) {
                        Object.keys(newSelected).forEach(v => newSelected[v] = false);
                        for (var i = lastSelectedIndex; i <= selectedIndex; i++) {
                            newSelected[allNodes[i].id] = true;
                        }
                    } else {
                        Object.keys(newSelected).forEach(v => newSelected[v] = false);
                        for (var i = selectedIndex; i <= lastSelectedIndex; i++) {
                            newSelected[allNodes[i].id] = true;
                        }
                    }
                } else {
                    newSelected[allNodes[selectedIndex].id] = true;
                }
            }
            else if (e.metaKey || e.ctrlKey) {
                newSelected[uuid] = !newSelected[uuid];
            } else { 
                Object.keys(newSelected).forEach(v => newSelected[v] = false);
                newSelected[uuid] = true;
            }
        }

        setSelected(newSelected);
        setLastSelectedIndex(selectedIndex);
    }

    return (
        <Container width={hierarchyWidth}>
            <WidthDrag onMouseDown={(e) => {
                setDragging(true); 
                }} />
            <Content ref={contentRef}>
                <Node node={model} tabIndex={1} uuid={model.uuid} selected={selected} onSelectionChanged={onSelectionChanged} />
            </Content>
        </Container>
    );
}

const Node = ({node, tabIndex, uuid, selected, onSelectionChanged}) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <NodeContainer className="NodeContainer" id={uuid} style={{paddingLeft : `${tabIndex * 10}px`}} onClick={(e) => onSelectionChanged(uuid, e)} selected={selected && selected[uuid]} onDoubleClick={(e) => onSelectionChanged(uuid, e, true)}>
                <ExpandIcon visible={node.children.length > 0} onClick={(e) => {e.stopPropagation(); setExpanded(!expanded);}} src={ico_arrow} expanded={expanded} />
                <NodeIcon src={node.geometry ? ico_mesh : ico_folder} />
                <Label>{node.name}</Label>
            </NodeContainer> 
            {expanded && node.children.map(child => <Node node={child} key={child.uuid} tabIndex={tabIndex + 1} uuid={child.uuid} selected={selected} onSelectionChanged={onSelectionChanged} />)}
        </>
    );
}

const WidthDrag = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    width: 10px;
    cursor: col-resize;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    // min-width: ${(props) => props.width}px;
    // width: ${(props) => props.width}px;

    flex-basis: ${(props) => props.width}px;
    flex-grow: 0;
    height: 100%;
    background: #fcfcfc;
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.05);
    overflow-y: auto;
    overflow-x: hidden;
    user-select: none;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 0px;
    font-family: 'SkyTextRegular';
`;

const NodeContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px 4px 4px;
    gap: 8px;
    height: 28px;

    cursor: pointer;

    background-color: ${(props) => (props.selected) ? '#f5f5f5'  : 'unset'};
    color: ${(props) => (props.selected) ? '#0072c9'  : '#333333'};

    :hover {
        background-color: #f0f0f0;
    }
`;

const ExpandIcon = styled.img`
    width: 12px;
    height: 12px;
    flex-grow: 0;
    flex-shrink: 0;

    visibility: ${(props) => (props.visible) ? 'visible' : 'hidden' };

    cursor: ${(props) => (props.visible) ? 'pointer' : 'default' };

    transition: transform 0.1s;
    transform: rotate(${(props) => (props.expanded) ? '0deg' : '-90deg'});

    :hover {
        filter: invert(48%) sepia(96%) saturate(340%) hue-rotate(163deg) brightness(92%) contrast(93%);
    }
`;

const NodeIcon = styled.img`
    width: 18px;
    height: 18px;
    flex-grow: 0;
    flex-shrink: 0;
`;

const Label = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    font-size: 13px;
    line-height: 18px;
`;

import { useEffect, useState } from "react";
import styled from "styled-components"
import modelStore from "../../../lib/stores/store";
import ico_info from "../../../assets/images/ico_info.svg";
import { ButtonPrimary } from "../molecules/buttons/ButtonPrimary";
import { FileBrowser } from "./FileBrowser";
import { FilenameInput } from "./FilenameInput";
import { ProgressBar } from "../atoms/ProgressBar";
import { ModalSave } from "./ModalSave";
import { uploadLargeFile, uploadSmallFile } from "../../../utils/uploadUtils";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import { generateExportHierarchy, generateTemplateJSON } from "../../../utils/exportUtils";
import { Box3, Vector3 } from "three";
import { Checkbox } from "./Checkbox";

const SITE_ID =
  "97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1";

export const UploadModal = ({onClose, uploadType, setExportStatus, selectedObjects, environment}) => {
    const {masterFile, model, setGraphIdStore, setTemplateIdStore} = modelStore();
    const [filePath, setFilePath] = useState("");
    const [modalPage, setModalPage] = useState("browser");
    const [uploadStatus, setUploadStatus] = useState("Exporting");
    const [uploadPercentage, setUploadPercentage] = useState("0%");
    const [graphId, setGraphId] = useState(null);

    const [exportRecenter, setExportRecenter] = useState(false);
    const [exportAlign, setExportAlign] = useState(true);
    const [exportAnimations, setExportAnimations] = useState(true);

    const onUpload = (filename) => {
        console.log(filename, uploadType, filePath);

        if (uploadType === "archive") {
            uploadArchiveFile(filename)
        } else if (uploadType === "export") {
            exportModel(filename);
        } else if (uploadType === "template") {
            uploadTemplate(filename);
        }
    }

    const uploadArchiveFile = (filename) => {
        var uploadFile = new File([masterFile], filename, {
            type: masterFile.type,
            lastModified: masterFile.lastModified,
        });

        setUploadStatus("Saving");
        setModalPage("progress");

        uploadLargeFile(uploadFile, SITE_ID, filePath, setUploadStatus, setUploadPercentage)
        .then((uploadResult) => {
            setGraphIdStore(uploadResult._responseBody.id);
            setModalPage("uploaded");
        }).catch((error) => {
            setModalPage("error");
        });
    }

    const exportModel = (filename) => {
        setUploadStatus("Exporting");
        setModalPage("progress");
        const exporter = new GLTFExporter();
        setExportStatus("started");
        var exportHierarchy = generateExportHierarchy(model, selectedObjects, exportAnimations);

        if (exportRecenter) {
            var bounds = new Box3();
            for (var i = 0; i < exportHierarchy.children.length; i++) {
                bounds.expandByObject(exportHierarchy.children[i], true);
            }
            var center = new Vector3();
            bounds.getCenter(center);

            if (exportAlign) {
                var size = new Vector3();
                bounds.getSize(size);
                center.y -= size.y / 2;
            }

            for (var j = 0; j < exportHierarchy.children.length; j++) {
                exportHierarchy.children[j].position.sub(center);
            }
        }

        setTimeout(() => {
        exporter.parse(
            exportHierarchy.children,
            function (gltf) {
            console.log(gltf);
            const uploadFile = new File([gltf], filename, {
                type: "application/octet-stream",
            });
            setExportStatus("finished");  
            uploadLargeFile(uploadFile, SITE_ID, filePath, setUploadStatus, setUploadPercentage)
                .then((uploadResult) => {
                    setGraphId(uploadResult._responseBody.id);
                    setModalPage("uploaded");
                })
                .catch((error) => {
                    setModalPage("error");
                });
            },
            function (error) {
                setModalPage("error");
                setExportStatus("finished");
            },
            { binary: true, animations: exportAnimations ? exportHierarchy.animations : [] }
        );
        }, 500);
    }

    const uploadTemplate = (filename) => {
        var templateJson = generateTemplateJSON(filename, selectedObjects, environment);
    
        setUploadStatus("Uploading Template");
        setModalPage("progress");
    
        uploadSmallFile(templateJson, SITE_ID, filePath, filename).then((res) => {
            console.log(res);
            if (res) {
                setTemplateIdStore(res);
                setGraphId(res);
                setModalPage("uploaded");
            } else {
                setModalPage("error");
            }
        })
    }

    return (
        <Container>
            <FullscreenDarken />
            <Modal theme={modalPage === "error" ? "red" : "blue"}>
                {modalPage === "browser" && <BrowserModal onCancel={onClose} uploadType={uploadType} setFilePath={setFilePath} onUpload={onUpload} />}
                {modalPage === "progress" && <ProgressBar uploadStatus={uploadStatus} uploadPercentage={uploadPercentage} />}
                {modalPage === "uploaded" && <UploadedModal uploadType={uploadType} onDone={onClose} graphId={graphId} />}
                {modalPage === "error" && <ErrorModal uploadType={uploadType} onClose={onClose} />}
            </Modal>
            {/* {modalPage === "browser" && uploadType === "export" &&
            <Modal theme="blue" style={{width: "auto", minWidth: "unset", position: "absolute", marginRight: "12px", left: "calc(max(calc(50% + 242px), calc(62.5%)) + 36px)"}}>
                <Title>Export Options</Title>
                <Checkbox label="Recenter export" defaultCheck={exportRecenter} onCheckChanged={setExportRecenter} />
                <Checkbox label="Align to floor" defaultCheck={exportAlign} onCheckChanged={setExportAlign} disabled={!exportRecenter} />
                {model && model.animations.length > 0 && <Checkbox label="Export animations" defaultCheck={exportAnimations} onCheckChanged={setExportAnimations} />}
            </Modal>} */}
        </Container>
    )
}

const ErrorModal = ({uploadType, onClose}) => {
    return (
        <>
            <Title style={{color: "#ff6240"}}>Couldn't {uploadType === "export" ? "export" : "save"} file.</Title>
            <p>Check your internet connection and try again.</p>
            <ButtonsContainer>
                <ButtonContainer>
                    <ButtonPrimary
                        style={{padding: "10px 18px", cursor: 'pointer'}}
                        text={"Close"}
                        theme={"blue"}
                        onClick={onClose}
                    />
                </ButtonContainer>
            </ButtonsContainer>
        </>
    );
}

const UploadedModal = ({uploadType, onDone, graphId}) => {
    return (
        <>
            {uploadType === "archive" && 
            <>
                <InfoIcon src={ico_info} />
                <Title style={{color: "#666666"}}>Your original file has been saved</Title>
            </>}
            {uploadType !== "archive" && 
            <>
            <Title>Your {uploadType === "template" ? "template" : "model"} has been exported</Title>
            <ModalSave
                text={"Axis Player Deeplink"}
                secondaryText={`axisPlayer://${uploadType === "template" ? "template" : "item"}Id?${graphId}`}
                style={{ width: "100%", textAlign: "center", paddingBottom: "12px"}}
                buttonStyle={{display: "inline-flex"}}
                toastStyle={{position: "unset", width: "80px", marginLeft: "auto", marginTop: "-20px", right: "0", height: "unset", padding: "0 26px 0 0"}}
                iconStyle={{ height: "20px", marginLeft: "auto" }}
                theme={"blue"}
            />
            </>}
            <ButtonsContainer>
                <ButtonContainer>
                    <ButtonPrimary
                        style={{padding: "10px 18px", cursor: 'pointer'}}
                        text={"Done"}
                        theme={"blue"}
                        onClick={onDone}
                    />
                </ButtonContainer>
            </ButtonsContainer>
        </>
        
    );
}

const BrowserModal = ({uploadType, setFilePath, onUpload, onCancel}) => {
    const { name } = modelStore();
    const [tempFilename, setTempFilename] = useState(name ? name : null);
    const [validFilename, setValidFilename] = useState(true);
    const [validPath, setValidPath] = useState(false);

    useEffect(() => {
        setValidFilename(true);
    }, [tempFilename]);

    const onSave = () => {
        var uploadFilename = tempFilename ? tempFilename : name;
        if (uploadType === "template") {
            if (!uploadFilename || uploadFilename === "") {
                setValidFilename(false);
                return;
            }
            if (!uploadFilename.endsWith(".json")) uploadFilename += ".json";
        } else if (uploadType === "export") {
            if (!uploadFilename.endsWith(".glb")) uploadFilename += ".glb";
        } else if (uploadType === "archive") {
            var extension = `.${name.split(".").pop()}`;
            if (!uploadFilename.endsWith(extension)) uploadFilename += extension;
        }
        if (onUpload) onUpload(uploadFilename);
    }

    return (
        <>
            <Title>
                {
                uploadType === "export" ? "Select a folder and export the file as GLB" :
                uploadType === "archive" ? "Select a folder and archive the file" :
                "Select a folder and save the template"
                }
            </Title>
            <FilenameInput setFilename={setTempFilename} validFilename={validFilename} placeholder={name} />
            <FileBrowser
                style={{height: "50vh", flexGrow: 1, margin: "0"}}
                onError={() => setValidPath(false)}
                onLoaded={() => setValidPath(true)}
                onPathChanged={setFilePath}
                baseDir={"Assets"}
                siteId={SITE_ID}
            />
            <ButtonsContainer>
                <ButtonContainer>
                    <ButtonPrimary
                        style={{padding: "10px 18px"}}
                        text={"Cancel"}
                        theme={"white"}
                        onClick={onCancel}
                    />
                </ButtonContainer>

                <ButtonContainer>
                    <ButtonPrimary
                        style={{padding: "10px 18px", cursor: validPath ? 'pointer' : 'default'}}
                        text={
                            uploadType === "export" ? "Export as GLB":
                            uploadType === "archive" ? "Archive original file":
                            "Save template"
                        }
                        theme={validPath ? "blue" : "red"}
                        onClick={validPath ? onSave : undefined}
                    />
                </ButtonContainer>
            </ButtonsContainer>
        </>
    )
}

const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    min-height: 750px;
    gap: 12px;
`;

const FullscreenDarken = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 0.7;
    z-index: 3;
`;

const Modal = styled.div`
    position: relative;
    background: #fcfcfc;
    border-top: 14px solid ${props => props.theme === "blue" ? "#0072c9" : "#ff6240"};
    height: fit-content;
    max-height: fit-content;
    opacity: 1;
    z-index: 4;
    filter: drop-shadow(0px 0px 20px rgba(51, 51, 51, 0.05));
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 36px 24px;
    margin-top: 56px;
    font-family: "SkyTextRegular";
    transition: all 1s;
    min-width: 484px;
    width: 25%;
`;

const Title = styled.div`
    font-size: 20px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: #0072c9;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0px 0px;
    gap: 24px;
    order: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

const InfoIcon = styled.img`
    height: 48px;
    width: 48px;
`;
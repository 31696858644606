import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

export const Checkbox = ({label, onCheckChanged, defaultCheck = false, disabled = false}) => {
    const [check, setCheck] = useState(defaultCheck);
    useEffect(() => {
        if (onCheckChanged) onCheckChanged(check);
    }, [check, onCheckChanged]);

    const toggleCheck = () => {
        if (!disabled) setCheck(!check);
    }

    return (
        <Container>
            <Check type="checkbox" onClick={toggleCheck} checked={check} disabled={disabled} readOnly/>
            <Divider />
            <Label onClick={toggleCheck} disabled={disabled}>{label}</Label>
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    gap: 12px;
    box-sizing: border-box;
    width: 100%;
`;

const Check = styled.input`
    margin-left: 12px;
    width: 16px;
    height: 16px;
    cursor: ${(props) => props.disabled ? 'unset' : 'pointer'};
`;

const Divider = styled.div`
    height: 36px;
    background: #f5f5f5;
    width: 1px;
`;

const Label = styled.div`
    font-family: 'SkyTextRegular';
    font-size: 16px;
    line-height: 18px;
    color: #333333;
    cursor: ${(props) => props.disabled ? 'unset' : 'pointer'};
`;
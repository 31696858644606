import styled from "styled-components";
import { Select } from "../molecules/select/select";
import ico_play from "../../../assets/images/ico_play.svg";
import ico_pause from "../../../assets/images/ico_pause.svg";

export const AnimationBar = (props) => {
    return (
        <Container>
            {props.disabled && <DisabledOverlay />}
            <Label>Animations:</Label>
                <Select disabled={props.disabled} initialValue={"No Animation"} values={["No Animation", ...props.animations]} onChange={(v) => props.onChange(v)} />
            <Button onClick={() => props.disabled ? undefined : props.togglePlay()}>
                {props.isPlaying ? <Pause src={ico_pause} /> : <Play src={ico_play} />}
            </Button>
        </Container>
    );
}

const DisabledOverlay = styled.div`
    width: 100%;
    height: 100%;
    background: #a5a5a5;
    position: absolute;
    z-index: 2;
    opacity: 70%;
    border-radius: 8px;
`;

const Container = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    gap: 12px;
    position: absolute;
    width: min-content;
    height: 50px;
    bottom: 5%;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    background: #FCFCFC;
    border-radius: 8px;
    font-family: "SkyTextMedium";
    justify-content: center;
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;

const Button = styled.div`
    width: 26px;
    height: 26px;
    border: 1px solid #0072C9;
    border-radius: 2px;
    flex: none;
    order: 2;
    flex-grow: 0;
    display: flex;
    cursor: pointer;

    :hover * {
        filter: invert(58%) sepia(23%) saturate(1405%) hue-rotate(167deg) brightness(88%) contrast(89%);
    }
`;

const Play = styled.img`
    flex-grow: 1;
    padding: 7.18px 8.5px 7.71px 10.35px;
`;

const Pause = styled.img`
    flex-grow: 1;
    padding: 7.18px 9.29px 7.71px 9.51px;
`;
import { Mesh, MeshStandardMaterial } from "three";

export const optimiseModel = (model) => {
    const optimised = model;

    optimised.traverse(function(object) {
      if (object instanceof Mesh && object.material) {
        if (Array.isArray(object.material)) {
          for (var i = 0; i < object.material.length; i++) {
            // object.material[i] = new MeshStandardMaterial().copy(object.material[i]);

            if (object.material[i].aoMap) {
              adjustUVs(object.geometry, object.material[i]);
            }
          }
        } else {
          // object.material = new MeshStandardMaterial().copy(object.material);
          if (object.material.aoMap) {
            adjustUVs(object.geometry, object.material);
          }
        }
      }
    });

    return optimised;
} 

const adjustUVs = (geometry, material) => {
    const aoTexture = material.aoMap;
    if (material.metalnessMap === aoTexture ||
        material.roughnessMap === aoTexture) {
        // If aoMap shares a texture with other maps, overwrite UV2 with UV1 layer
        geometry.setAttribute( 'uv2', geometry.attributes.uv);
    } else if (!geometry.hasAttribute('uv2')) {
        // If there is an aoMap but there is no uv2 channel, copy the first
        geometry.setAttribute( 'uv2', geometry.attributes.uv);
    }
}
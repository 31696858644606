import { Matrix4, Vector4 } from "three";
import { Light } from "three";
import { Camera } from "three";
import { Bone, Object3D, Quaternion, Scene, Skeleton, SkinnedMesh, Vector3 } from "three";
import { getDownloadUrl } from "./uploadUtils";

export const generateExportHierarchy = (model, selection, exportAnimations) => {
  /* RULES: 
    * no animations (static) = generate flat, no need for folders/etc.
    * with animations = hierarchy required to maintain skeletons/animations
  
  */
  var exportHierarchy;
  var tempSelection = {};
  if (!Object.values(selection).includes(true)) {
    model.traverse(function(node) {
      tempSelection[node.uuid] = true;
    });
  } else {
    tempSelection = selection;
  }

  if (model.animations && model.animations.length > 0 && exportAnimations) {
      exportHierarchy = generateHierarchicalSelection(model, tempSelection);
  } else {
    exportHierarchy = generateFlatSelection(model, tempSelection);
  }

  return exportHierarchy;
}


// Return flat selection where all meshes are direct children of the scene
export const generateFlatSelection = (model, selection) => {
    var obj = new Scene();
    model.traverse(function(node) {
      if (selection[node.uuid]) {
        var clone = node.clone(false);
        var pos = new Vector3();
        var rot = new Quaternion();
        var scale = new Vector3();
        node.getWorldPosition(pos);
        node.getWorldQuaternion(rot);
        node.getWorldScale(scale);
        clone.position.set(pos.x, pos.y, pos.z);
        clone.setRotationFromQuaternion(rot);
        clone.scale.set(scale.x, scale.y, scale.z);
        clone.name = node.name;
        obj.add(clone);
      } 
    });
    return obj;
}

// Try to maintain the hierarchy for the export as best as possible
export const generateHierarchicalSelection = (model, selection) => {
  var clone = model.clone(false);
  clone.animations = model.animations;
  var cloneMap = {};

  var requireBones = false;
  Object.keys(selection).forEach(v => {
    if (model.getObjectByProperty('uuid', v) instanceof SkinnedMesh) requireBones = true;
  });

  var boneClones = requireBones ? {} : null;

  for (var i = 0; i < model.children.length; i++) {
    addNode(clone, model.children[i], selection, cloneMap, boneClones);
  }

  if (requireBones) cleanSkinnedMeshes(clone, cloneMap, boneClones);
  return clone;
}

// Clone a node's transform to a new object
const shallowClone = (node, cloneMap = {}) => {
  var clone;
  var pos = new Vector3();
  var rot = new Quaternion();
  var scale = new Vector3();
  node.getWorldPosition(pos);
  node.getWorldQuaternion(rot);
  node.getWorldScale(scale);
  clone = new Object3D();
  cloneMap[clone.uuid] = node;
  clone.position.set(pos.x, pos.y, pos.z);
  clone.setRotationFromQuaternion(rot);
  clone.scale.set(scale.x, scale.y, scale.z);
  clone.name = node.name;
  return clone;
}

const addNode = (parent, node, selection, cloneMap, boneClones) => {
  if (selection[node.uuid] || hasRequiredChild(node, selection, boneClones !== null)) {
    var clone;
    if (selection[node.uuid] || (boneClones && (node instanceof Bone || node instanceof Skeleton))) {
      clone = node.clone(false);
      cloneMap[clone.uuid] = node;
      if (clone.material) {clone.material.metalness = 0; clone.material.roughness = 0.5;}
      if (boneClones && node instanceof Bone) boneClones[node.uuid] = clone;
    } else {
      clone = shallowClone(node, cloneMap);
    }
    if (parent) parent.add(clone);
    for (var i = 0; i < node.children.length; i++) {
      addNode(clone, node.children[i], selection, cloneMap, boneClones);
    }
  }
}

const hasRequiredChild = (object, selection, bonesRequired) => {
  var required = false;
  object.traverse(function(node) {
    if (selection[node.uuid]) required = true;
    else if (bonesRequired && (node instanceof Bone || node instanceof Skeleton)) required = true;
  });
  return required;
}

function cleanSkinnedMeshes(clone, cloneMap, boneClones) {
  clone.traverse(function(node) {
    if (node instanceof SkinnedMesh) {
      node.skeleton = cloneMap[node.uuid].skeleton.clone();
      node.bindMatrix.copy(cloneMap[node.uuid].bindMatrix);
      node.skeleton.bones = cloneMap[node.uuid].skeleton.bones.map(function(bone) {
        // copy each bone, if already exists, use existing bone
        return boneClones[bone.uuid];
      });
    }
  });

}

export async function generateTemplatePreviewJSON(siteId, allObjects, environment) {
  var promise = new Promise(function(resolve) {
    var template = generateTemplateJSON("preview", allObjects, environment);
    var promises = [];
    if (template.environment) {
      promises.push(getDownloadUrl(siteId, template.environment.id).then((url) => template.environment.url = url));
    }
    template.models.forEach((v, i) => {
      promises.push(getDownloadUrl(siteId, v.id).then((url) => v.url = url));
    });
    Promise.all(promises).then((values) => {
      console.log(values);
      resolve(template);
    })
  })
  return await promise;
}

export const generateTemplateJSON = (filename, allObjects, environment) => {
  var templateJson = {
    name: filename,
    models: [],
    cameras: [],
    lights: [],
  };

  if (environment && environment.id) {
    templateJson["environment"] = {
      id: environment.id,
      rotation: environment.rotation,
      castShadows: environment.castShadows
    }
  }

  var camIndex = 0, lightIndex = 0;

  for (var i = 0; i < allObjects.length; i++) {
    var obj = allObjects[i];
    if (obj instanceof Camera) {
      var worldVec = new Vector3();
      obj.getWorldDirection(worldVec);
      var mx = new Matrix4().lookAt(worldVec,new Vector3(0,0,0),new Vector3(0,1,0));
      var cameraForwardsQuaternion = new Quaternion().setFromRotationMatrix(mx);

      templateJson.cameras.push(
        {
          id: camIndex++,
          position: obj.position,
          threeQuaternion: obj.quaternion,
          quaternion: cameraForwardsQuaternion,
          scale: obj.scale,
          fov: obj.fov,
          focalLength: obj.getFocalLength(),
          near: obj.near,
          far: obj.far,
          type: obj.cameraType,
          orbitRadius: obj.orbitRadius,
          orbitHeight: obj.orbitHeight,
          title: obj.title,
          synopsis: obj.synopsis
        }
      );
    } else if (obj.lightType) {
      templateJson.lights.push(
        {
          id: lightIndex++,
          position: obj.position,
          quaternion: obj.quaternion,
          scale: obj.scale,
          color: obj.color,
          intensity: obj.intensity,
          type: obj.lightType,
          distance: obj.distance,
          angle: obj.angle,
          penumbra: obj.penumbra,

        }
      );
    } else if (obj.graphId !== null) {
      var idle = null;
      if (obj.animations && obj.animations.length > 0) {
        for (var j = 0; j < obj.animations.length; j++) {
          if (obj.animations[j].name.toLowerCase().includes('idle')|| (obj.animations.length === 1 && j === 0)) {
            idle = obj.animations[j].name;
          }
        }
      }
      templateJson.models.push(
        {
          id: obj.graphId,
          position: obj.position,
          quaternion: obj.quaternion,
          scale: obj.scale,
          idleAnimation: idle
        }
      );
    }
  }

  return templateJson;
}
import styled from "styled-components";

export const ProgressBar = (props) => {
  return (
    <Container>
      <Head style={props.headStyle}>
        <Title>{props.uploadStatus}</Title>
        <Title>{props.uploadPercentage}</Title>
      </Head>
      <ProgressContainer>
        <Bar style={{ width: props.uploadPercentage }}></Bar>
      </ProgressContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 48px 48px 48px;
`;

const ProgressContainer = styled.div`
  height: 10px;
  border-radius: 1rem;
  transition: all 0.5s;
  will-change: transform;
  border: 1px solid #0072c9;
`;

const Bar = styled.div`
  height: 100%;
  border-radius: 1rem;
  transition: all 0.5s;
  background-color: #0072c9;
`;

const Title = styled.h2`
  font-family: "SkyTextMedium";
  font-size: 14px;
  color: #0072c9;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  background: #fcfcfc;
  justify-content: space-between;

  ${Title}:last-child {
    align-self: flex-end;
  }
`;
import { useState } from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../buttons/ButtonPrimary";
import ico_down_arrow from "../../../../assets/images/ico_down_arrow.svg";
import ico_success from "../../../../assets/images/success.png";

export const Select = ({label, values, onChange, initialValue = "", disabled}) => {
    const [currentValue, setCurrentValue] = useState(initialValue);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleValueChange = (value) => {
        setCurrentValue(value);
    };
    const handleChange = (value) => {
        handleValueChange(value);
        // call method, if it exists
        if (onChange) onChange(value);
        // close, after all tasks are finished
        handleClose();
    };

    return (
        <SelectContainer>
            <ButtonPrimary
            onClick={disabled ? undefined : handleOpen}
            text={currentValue !== "" ? currentValue.length > 15 ? currentValue.substring(0, 15).concat("...") : currentValue : label}
            style={{ width: "180px", height: "28px", border: "1px solid #0072C9", borderRadius: "2px", textAlign: "left", fontFamily:"SkyTextMedium", cursor: "pointer" }}
            leftAlign={true}
            icon={ico_down_arrow}
            iconStyle={{ height: "7px" }}
            theme={"white"}
            />
            <DropdownStyle isVisible={open}>
                {values.map((value, index) => (
                    <DropdownItem
                        onClick={() => disabled ? undefined : handleChange(value)}
                        active={value === currentValue}
                        key={index}>
                        {value}
                    </DropdownItem>
                ))}
            </DropdownStyle>
        </SelectContainer>
    );
};

const SelectContainer = styled.div`
    position: relative;
    margin: 0;
`;

const DropdownStyle = styled.div`
    align-items: center;
    padding: 0px;
    width: 99%;
    max-height: 25vh;
    scroll-y:visible;
    position: absolute;
    left: 0;
    bottom: calc(100% - 1px);
    background: #FCFCFC;
    border: 1px solid #0072C9;
    border-radius: 2px;
    flex: none;
    order: 1;
    flex-grow: 0;
    overflow-y: auto;
    overflow-x: hidden;
    ${(p) =>
    p.isVisible !== true &&
    `
    visibility: hidden;
    `}
`;

const DropdownItem = styled.div`
    display: flex;
    align-items: center;
    padding: 0.3rem 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    cursor: pointer;
    ${(p) =>
    p.active &&
    `
    color: #166edc;
    font-weight: 500;
    `}
    &:hover, :focus, :focus:hover {
    background-color: #166edc;
    color: #fafafa;
    outline: none;
    }
`;
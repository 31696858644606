import { Box3, Vector3 } from "three";

export const getObjectBounds = (object, precise = false) => {
    var box = new Box3();
    object.updateWorldMatrix( false, false );
    const geometry = object.geometry;
    if ( geometry !== undefined ) {
        if ( precise && geometry.attributes !== undefined && geometry.attributes.position !== undefined ) {
            const position = geometry.attributes.position;
            for ( let i = 0, l = position.count; i < l; i ++ ) {
                var vector = new Vector3();
                vector.fromBufferAttribute( position, i ).applyMatrix4( object.matrixWorld );
                box.expandByPoint( vector );
            }
        } else {
            if ( geometry.boundingBox === null ) {
                geometry.computeBoundingBox();
            }
            box.copy( geometry.boundingBox );
            box.applyMatrix4( object.matrixWorld );
        }
    }
    return box;
}
import { Providers } from "@microsoft/mgt-element";
import { FileUpload, LargeFileUploadTask } from "@microsoft/microsoft-graph-client";
import { async } from "rxjs";

export async function uploadLargeFile(file, siteId, filePath, setUploadStatus, setUploadPercentage) {
    const progress = (range) => {
      setUploadStatus("Saving file...");
      setUploadPercentage(
        `${Math.round((parseFloat(range.maxValue) / file.size) * 100)}%`
      );
    };

    const uploadEventHandlers = {
      progress,
    };

    const options = {
      rangeSize: 1024 * 1024,
      uploadEventHandlers: uploadEventHandlers,
    };

    const uploadSession = await LargeFileUploadTask.createUploadSession(
      Providers.client,
      `https://graph.microsoft.com/v1.0/sites/${siteId}/drive/root:/${filePath}/${file.name}:/createuploadsession`
    );
    const fileObject = new FileUpload(file, file.name, file.size);
    const task = new LargeFileUploadTask(
      Providers.client,
      fileObject,
      uploadSession,
      options
    );

    setUploadStatus("Initialising Upload");

    const uploadResult = await task.upload();
    return uploadResult;
}

export async function getSpecs() {
  let promise = new Promise(function(resolve) {
    Providers.client.api("/sites/97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1/drive/items/01FZRWYL34S62YUNC4DJFZPOT7MJOUIB43/workbook/worksheets('sheet1')/range(address='A1:G3')?$select=text")
    .get().then((e) => {
      var specs = {};
      for (var i = 1; i < e.text.length; i++) {
        var device = e.text[i];
        specs[device[0]] = {};
        for (var j = 0; j < device.length; j++) {
          specs[device[0]][e.text[0][j]] = device[j];
        }
      }
      resolve(specs);
    }).catch((e) => {
      console.log(e);
      resolve(null);
    });
  });
  return await promise;
}

export async function getEnvironments() {
  let promise = new Promise(function(resolve) {
    Providers.client.api("/sites/97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1/drive/root:/Environments:/children")
    .get().then((e) => {
      if (!e.value) resolve(null);
      var environments = {};
      Object.keys(e.value).forEach((v) => {
        var name = e.value[v].name;
        var id = e.value[v].id;
        var url = e.value[v]["@microsoft.graph.downloadUrl"];
        environments[name] = {id: id, url: url, extension: name.split(".").pop(), rotation: 0, castShadows: false};
      })
      resolve(environments);
    }).catch((e) => {
      console.log(e);
      resolve(null);
    });
  });
  return await promise;
}

export async function getEnvironment(environmentId) {
  let promise = new Promise(function(resolve) {
    Providers.client.api(`/sites/97451ed0-e22e-41dd-9fce-df261905c07c,57b87256-bb47-4d13-b679-d899e618f3a1/drive/items/${environmentId}`)
    .get().then((e) => {
      resolve({
        id: environmentId,
        url: e["@microsoft.graph.downloadUrl"],
        extension: e["name"].split(".").pop()
      });
    }).catch((e) => {
      console.log(e);
      resolve(null);
    });
  });
  return await promise;
}

export async function uploadSmallFile(content, siteId, filePath, filename) {
  let promise = new Promise(function(resolve) {
    Providers.client
    .api(`https://graph.microsoft.com/v1.0/sites/${siteId}/drive/root:/${filePath}/${filename}:/content`)
    .put(content)
    .then((res) => {
      resolve(res.id);
    }).catch((error) => {
      console.log(error);
      resolve(null);
    });
  });
  return await promise;
}

export async function getDownloadUrl(siteId, itemId) {
  let promise = new Promise(function(resolve) {
    Providers.client
    .api(`https://graph.microsoft.com/v1.0/sites/${siteId}/drive/items/${itemId}`)
    .get()
    .then((res) => {
      resolve(res["@microsoft.graph.downloadUrl"]);
    }).catch((error) => {
      console.log(error);
      resolve(null);
    });
  });
  return await promise;
}

export async function getDriveItem(siteId, itemId) {
  let promise = new Promise(function(resolve) {
    Providers.client
    .api(`https://graph.microsoft.com/v1.0/sites/${siteId}/drive/items/${itemId}`)
    .get()
    .then(resolve).catch((error) => {
      console.log(error);
      resolve(null);
    });
  });
  return await promise;
}

export async function getTemplate(siteId, templateId) {
  let promise = new Promise(function(resolve) {
    getDownloadUrl(siteId, templateId).then((url) => {
      fetch(url).then((res) => {
        res.json().then((json) => {

          var promises = [];

          if (json.environment) {
            promises.push(getDownloadUrl(siteId, json.environment.id).then((url) => {
              json.environment.url = url;
            }));
          }

          for (const model of json.models) {
            promises.push(getDriveItem(siteId, model.id).then((res) => {
              model.url = res["@microsoft.graph.downloadUrl"];
              model.name = res["name"];
            }));
          }

          Promise.all(promises).then(() => {
            resolve(json);
          });

        }).catch(resolve);
      }).catch(resolve);
    });
  });
  return await promise;
}